<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="currentCertificateBlankFileType && currentCertificateBlankFileType.originalIndex === 0 ? 'Mặt 1' : 'Mặt 2' "
              label-for="file"
            >
              <b-form-file
                ref="inputFileRef"
                :placeholder="placeholder"
                @change="uploadFile"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="float-left">
              <b-button
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="onSave('hide')"
              >
                Lưu
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('sampleCertificateBlankFileSaveModal')"
              >
                <span class="text-right">
                  <feather-icon icon="XIcon" /> Hủy
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { required } from '@vuelidate/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'SampleCertificateBlankFileSave',
  components: {
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentCertificateBlankFileType: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      mediaIds: [],
      targetItem: {
        name: '',
        description: '',
        credentialTypeId: 0,
        type: '',
        number: 0,
        codeStart: '',
        codeEnd: '',
        linkFileCover: '',
        linkFileMain: '',
        fileCoverId: 0,
        fileMainId: 0,
        status: 1,
      },
      currentCertificateBlankId: this.item.id,
      placeholder: 'Chọn file để tải ...',
      required,
    }
  },
  computed: {
    isCreated() {
      return !this.item
    },
  },
  created() {
    if (this.item) {
      this.targetItem = { ...this.item }
    }
    this.placeholder = this.currentCertificateBlankFileType.currentCertificateBlankFileName
  },
  methods: {
    ...mapActions({
      createCertificateBlank: 'certificateBlank/createCertificateBlank',
      updateCertificateBlank: 'certificateBlank/updateCertificateBlank',
      uploadSample: 'certificateBlank/uploadSample',
    }),
    getElementState(errors) {
      return !(errors.length > 0)
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateCertificateBlank(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('sampleCertificateBlankFileSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async getInitData() {
      // await this.getDocuments()
    },
    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))

      this.mediaIds = await this.uploadSample(formData)

      if (this.mediaIds.length > 0) {
        if (this.currentCertificateBlankFileType.originalIndex === 0) {
          this.targetItem.fileCoverId = this.mediaIds[0]
        } else if (this.currentCertificateBlankFileType.originalIndex === 1) {
          this.targetItem.fileMainId = this.mediaIds[0]
        }
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },
    async handleSaveData(e) {
      e.preventDefault()
    },
  },
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <div class="image-container">
      <b-row>
        <b-col>
          <b-card>
            <b-card-body>
              <img
                v-if="backgroundImageDisplay !== ''"
                class="background-image"
                :src="urlBackground"
                alt="Background Image"
              >
              <div
                ref="printTableContainer"
                class="table-container"
                :class="{ 'relative-position': (backgroundImageDisplay === '') }"
                :style="{ top: tableTop + 'px', left: tableLeft + 'px' }"
              >
                <table>

                  <tbody>
                    <tr
                      v-for="(row, rowIndex) in rows"
                      :key="rowIndex"
                    >
                      <td
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        :rowspan="cell.rowspan ? cell.rowspan : 1"
                        :colspan="cell.colspan ? cell.colspan : 1"
                      >
                        {{ cell.value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="rows.length < 1"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi hoặc bản ghi không được hỗ trợ xem trước!
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row
      v-if="typeView !== 2"
    >
      <b-col>
        <b-card
          mx-1
          class="card-with-border"
          border-variant="secondary"
          header="---Tùy chọn nội dung---"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="top"
                >
                  <b-row>
                    <b-col sm="1">
                      <label
                        :for="top"
                        class="my-1"
                      >Lề trên:</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                        :id="top"
                        v-model="top"
                        type="number"
                        @change="adjustTablePosition"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="left"
                >
                  <b-row>
                    <b-col sm="1">
                      <label
                        :for="left"
                        class="my-1"
                      >Lề trái:</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                        :id="left"
                        v-model="left"
                        type="number"
                        @change="adjustTablePosition"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="typeView === 0"
                cols=""
                class="text-left"
              >
                <b-form-group>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="setBackgroundImage"
                  >
                    Hiển thị nền
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="mx-2"
                    @click="onSaveConfig"
                  >
                    Lưu thông số
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col
                cols=""
                :class="typeView === 1 ? 'text-left' : 'text-right'"
              >
                <b-form-group>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="printTable"
                  >
                    <feather-icon icon="PrinterIcon" />
                    In
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-success"
                    class="mx-2"
                    @click="onDownload"
                  >
                    <feather-icon icon="DownloadIcon" />
                    Tải xuống
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCol, BRow, BFormInput, BButton, BCardBody, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BCardBody,
    BFormGroup,
  },
  props: {
    excelFile: {
      type: String,
      required: true,
      default: '',
    },
    backgroundImage: {
      type: String,
      required: true,
      default: '',
    },
    currentCredential: {
      type: Object,
      default: undefined,
    },
    typeView: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      headers: [],
      rows: [],
      url: this.excelFile,
      urlBackground: '',
      tableTop: 0,
      tableLeft: 0,
      top: 0,
      left: 0,
      backgroundImageDisplay: '',
      targetConfig: {
        x: 0,
        y: 0,
        credentialId: '',
        credentialSampleId: '',
        stylePaper: '',
        codeCurrent: '',
        codeCurrentCredentialStorage: '',
        currentYear: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      credentialSampleConfigs: 'credentialSampleConfig/credentialSampleConfigs',
    }),
  },
  watch: {
    excelFile: {
      immediate: true,
      async handler(newFile) {
        await this.updateUrlFile(newFile)
        this.loadExcelFile()
      },
    },
    backgroundImageDisplay: {
      immediate: true,
      async handler(newFile) {
        await this.updateUrlBackground(newFile)
      },
    },
  },
  async created() {
    await this.readCredentialSampleConfigs({
      credentialId: this.currentCredential.credentialId,
      credentialSampleId: this.currentCredential.id,
    })
    this.top = this.credentialSampleConfigs[0].y
    this.left = this.credentialSampleConfigs[0].x
  },
  methods: {
    ...mapActions({
      getPathFileSample: 'certificateBlank/getPathFileSample',
      createCredentialSampleConfig: 'credentialSampleConfig/createCredentialSampleConfig',
      readCredentialSampleConfigs: 'credentialSampleConfig/readCredentialSampleConfigs',
    }),
    setBackgroundImage() {
      if (this.backgroundImageDisplay === '') {
        this.backgroundImageDisplay = this.backgroundImage
      } else {
        this.backgroundImageDisplay = ''
        this.urlBackground = ''
      }
    },
    loadExcelFile() {
      fetch(this.url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          const data = new Uint8Array(arrayBuffer)
          const workbook = XLSX.read(data, { type: 'array' })
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          const sheetData = this.sheetToJson(sheet)

          if (sheetData.length > 0) {
            this.rows = sheetData
            this.headers = this.rows[0] // Assume first row as headers
            this.$nextTick(this.setBackgroundSize)
          }
        })
        .catch(error => {
          console.error('Error loading Excel file:', error)
        })
    },
    async updateUrlFile(url) {
      if (url.endsWith('.xlsx') || url.endsWith('.xls')) {
        this.url = await this.getPathFileSample({
          pathFile: url,
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      }
    },
    async updateUrlBackground(url) {
      this.urlBackground = await this.getPathFileSample({
        pathFile: url,
        contentType: 'image/jpeg',
      })
    },
    adjustTablePosition() {
      this.tableTop = parseInt(this.top)
      this.tableLeft = parseInt(this.left)
    },
    sheetToJson(sheet) {
      const range = XLSX.utils.decode_range(sheet['!ref'])
      const data = []
      const startCol = range.s.c // Bắt đầu lấy từ cột đầu tiên có dữ liệu
      const endCol = range.e.c // Lấy đến cột cuối cùng có dữ liệu

      for (let C = startCol; C <= endCol; ++C) {
        let columnHasData = false // Biến để kiểm tra xem cột có dữ liệu hay không
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cell_address = { c: C, r: R }
          const cell_ref = XLSX.utils.encode_cell(cell_address)
          const cell = sheet[cell_ref]
          if (cell && cell.v !== undefined && cell.v !== '') {
            columnHasData = true
            break // Nếu có ô có dữ liệu trong cột, thoát khỏi vòng lặp
          }
        }
        if (columnHasData) {
          const column = []
          for (let R = range.s.r; R <= range.e.r; ++R) {
            const cell_address = { c: C, r: R }
            const cell_ref = XLSX.utils.encode_cell(cell_address)
            let cell = sheet[cell_ref]
            if (!cell) {
              cell = { v: '' }
            }
            column.push({
              value: cell.v,
              rowspan: cell && cell.rowspan,
              colspan: cell && cell.colspan,
            })
          }
          data.push(column)
        }
      }
      return this.transposeArray(data)
    },
    transposeArray(array) {
      return array[0].map((_, colIndex) => array.map(row => row[colIndex]))
    },
    printTable() {
      const printContent = this.$refs.printTableContainer.outerHTML
      const printWindow = window.open('', '', 'height=600,width=800')
      printWindow.document.write('<style>')
      // Thêm CSS cần thiết cho phần in
      printWindow.document.write(`
        .table-container {
          position: absolute;
          top: 0;
          left: 0;
          border-collapse: collapse;
          border: none;
          padding: 20px;
          z-index: 1;
        }
        .table-container.relative-position {
          position: relative;
        }
        .table-container table {
          width: 100%;
        }
        .table-container th,
        .table-container td {
          border: none !important;
          padding: 8px;
          white-space: nowrap;
        }
        .image-container {
          position: relative;
          width: 100%;
          height: 100%;
        }
        .background-image {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 0;
        }
      `)
      printWindow.document.write('</style>')
      printWindow.document.write('</head><body>')
      printWindow.document.write(printContent)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.print()
    },
    onDownload() {
      const link = document.createElement('a')
      link.href = this.url
      link.download = this.url.split('/').pop()
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async onSaveConfig() {
      this.targetConfig = {
        x: parseInt(this.left),
        y: parseInt(this.top),
        credentialId: this.currentCredential.credentialId,
        credentialSampleId: this.currentCredential.id,
        stylePaper: '',
        codeCurrent: '',
        codeCurrentCredentialStorage: '',
        currentYear: '',
      }
      await this.createCredentialSampleConfig(this.targetConfig)
      this.isLoading = true
      try {
        const response = await this.createCredentialSampleConfig(this.targetConfig)
        if (response) {
          const {
            isSuccessful,
            message,
          } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.readCredentialSampleConfigs({
              credentialId: this.currentCredential.credentialId,
              credentialSampleId: this.currentCredential.id,
            })
            this.top = this.credentialSampleConfigs[0].y
            this.left = this.credentialSampleConfigs[0].x
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    if (this.excelFile) {
      this.updateUrlFile(this.excelFile)
        .then(() => {
          this.loadExcelFile()
          this.adjustTablePosition()
        })
    }
  },
}
</script>

<style>
.table-container {
  position: absolute;
  top: 0;
  left: 0;
  border-collapse: collapse;
  border: none;
  padding: 20px;
  z-index: 1;
  overflow: auto;
  max-width: 100%;
}
.table-container.relative-position {
  position: relative;
}
.table-container table {
  width: 100%;
}

.table-container th,
.table-container td {
  border: none !important;
  padding: 8px;
  white-space: nowrap;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.card-with-border {
  position: relative;
  z-index: 10; /* lớn hơn z-index của .table-container */
}
</style>

<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group
                      label="Phân loại"
                      label-for="filter.credentialType"
                  >
                    <v-select
                        v-model="filter.credentialType"
                        :options="types"
                        :reduce="option => option.value"
                        @input="onCredentialTypeChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group
                      label="Loại văn bằng, chứng chỉ"
                      label-for="credentialId"
                  >
                    <v-select
                        v-model="filter.credentialId"
                        :options="credentialDropdownOptions"
                        :reduce="option => option.value"
                        @input="onCredentialChange"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group
                      label="Năm học"
                      label-for="yearId"
                  >
                    <v-select
                        v-model="filter.yearId"
                        :options="schoolYearsOptions"
                        :reduce="option => option.value"
                        @input="onSchoolYearChange"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Tên học viên
                    </template>
                    <b-form-input
                        id="name"
                        v-model="filter.name"
                        placeholder="Tên học viên"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group label-for="code">
                    <template v-slot:label>
                      Số hiệu
                    </template>
                    <b-form-input
                        id="code"
                        v-model="filter.code"
                        placeholder="Số hiệu văn bằng, chứng chỉ"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                    cols="4"
                    class="text-left"
                >
                  <b-form-group label-for="codeCredentialStorage">
                    <template v-slot:label>
                      Số vào sổ gốc
                    </template>
                    <b-form-input
                        id="codeCredentialStorage"
                        v-model="filter.codeCredentialStorage"
                        placeholder="Số vào sổ gốc"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                    cols="6"
                    class="text-left"
                >
                  <b-button
                      variant="secondary"
                      class="my-2"
                      @click="downloadFile"
                  >
                        <span class="text-nowrap text-right">
                          <feather-icon icon="DownloadIcon" /> Tải file mẫu
                        </span>
                  </b-button>
                  <b-button
                      v-b-modal.importCredentialStorage
                      variant="outline-primary"
                      class="my-2 mx-2"
                  >
                    <feather-icon icon="UploadCloudIcon" /> Import văn bằng chứng chỉ
                  </b-button>
                </b-col>
                <b-col
                    cols="6"
                    class="text-right"
                >
                  <b-button-group>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="my-2 mx-1"
                        variant="primary"
                        @click="onSearch"
                    >
                      <feather-icon icon="SearchIcon" />
                      Tìm kiếm
                    </b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.credentialStorageSaveModal
                        class="my-2"
                        variant="success"
                        @click="addCredentialForStudent"
                    >
                      <feather-icon icon="PlusIcon" />
                      Bổ sung
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ filter.itemsPerPage * (filter.currentPage) }} của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="filter.credentialType === 0 ? columnsCertificate : columnsDegree"
                  :rows="studentsCredentialStorage"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'VBCC được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
              >
                <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  <span
                      v-if="props.column.field === 'action'"
                      class="thead-group-action"
                  >
                    <b-button-group>
                      <b-button
                          v-b-modal.credentialStorageSaveModal
                          class="btn-icon"
                          variant="primary"
                          title="Chỉnh sửa"
                          @click="onUpdateCredentialInStorage(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                          v-b-modal.previewCredentialModal
                          variant="warning"
                          class="btn-icon"
                          size="sm"
                          title="Xem trước file trước khi in"
                          @click="onPreview(props.row)"
                      >
                        <feather-icon icon="ZoomInIcon" />
                      </b-button>
                      <b-button
                          v-b-modal.viewStorageFileModal
                          variant="success"
                          class="btn-icon"
                          size="sm"
                          title="Xem file văn bằng, chứng chỉ"
                          @click="onPreview(props.row)"
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </b-button-group>
                  </span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
      <CredentialStorageSave
          ref="credentialStorageSaveModal"
          :item="currentStudentCredentialStorage"
          @succeed="onSucceed"
      />
      <b-modal
          id="previewCredentialModal"
          ref="previewCredentialModal"
          ok-title="Xác nhận"
          cancel-title="Hủy"
          centered
          size="xl"
      >
        <PreviewCredential
            :item="currentStudentCredentialStorage"
        />
      </b-modal>
      <b-modal
          id="viewStorageFileModal"
          ref="viewStorageFileModal"
          centered
          size="xl"
      >
        <ViewStorageFile
            :item="currentStudentCredentialStorage"
        />
      </b-modal>
      <b-modal
          id="importCredentialStorage"
          ref="importCredentialStorage"
          centered
          size="xl"
      >
        <ImportCredentialStorage />
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup, BFormInput, BFormSelect, BModal, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import SampleCertificateBlank from '@/views/certificate-blank/SampleCertificateBlank.vue'
import { VueGoodTable } from 'vue-good-table'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import CredentialStorageSave from '@/views/credential-storage/CredentialStorageSave.vue'
import PreviewCredential from '@/views/credential-storage/PreviewCredential.vue'
import ViewStorageFile from '@/views/credential-storage/ViewStorageFile.vue'
import { Workbook } from '@syncfusion/ej2-excel-export'
import ImportCredentialStorage from '@/views/credential-storage/ImportCredentialStorage.vue'

export default {
  name: 'CredentialStorage',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ImportCredentialStorage,
    ViewStorageFile,
    PreviewCredential,
    BModal,
    ValidationProvider,
    BFormInput,
    SampleCertificateBlank,
    CredentialStorageSave,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        credentialType: 1,
        credentialId: null,
        yearId: null,
        name: null,
        statusPrint: null,
        code: null,
        codeCredentialStorage: null,
      },
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentStudentCredentialStorage: undefined,
      credentialStorageTest: [
        {
          id: 276,
          codeStudent: '2552020474',
          name: 'Nguyễn Thị Thu An',
          major: 'Sư phạm tiểu học',
          ethnic: 'Kinh',
          gender: 'Không xác định',
          address: 'Ninh Bình',
          birthday: '25/05/2003',
          nationality: 'Việt Nam',
          credentialName: 'Chứng chỉ ngoại ngữ',
          codeCredential: 'abc1232024',
          code: 'abc123',
          year: '2021-2022',
          graduationText: 'Giỏi',
        },
      ],
      selectedMainData: [],
      columnsDegree: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Quốc tịch',
          field: 'nationality',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngành đào tạo',
          field: 'major',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên văn bằng',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu văn bằng',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      columnsCertificate: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu chứng chỉ',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    studentsCredentialStorage: {
      handler(newVal) {
        newVal.forEach(item => {
          for (let key in item) {
            if (item[key] === null) {
              item[key] = ''
            }
          }
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      totalRows: 'credentialStorage/totalRows',
      studentsCredentialStorage: 'credentialStorage/studentsCredentialStorage',
      types: 'credentialStorage/types',
      schoolYears: 'dropdown/schoolYears',
      credentialsDropdown: 'dropdown/credentials',
      statuses: 'credentialStorage/statuses',
    }),
    schoolYearsOptions() {
      return [{ label: 'Tất cả năm học', value: null }, ...this.schoolYears]
    },
    credentialDropdownOptions() {
      return [{ label: 'Tất cả ', value: null }, ...this.credentialsDropdown]
    },

  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getSchoolYears(),
        this.getAllCredentials(this.filterGetAll),
        this.readStudentsCredentialStorage({
          currentPage: this.filter.currentPage,
          itemsPerPage: this.filter.itemsPerPage,
          credentialType: this.filter.credentialType,
          credentialId: this.filter.credentialId,
          yearId: this.filter.yearId,
          name: this.filter.name,
          statusPrint: this.filter.statusPrint,
          code: this.filter.code,
          codeCredentialStorage: this.filter.codeCredentialStorage,
        }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readStudentsCredentialStorage: 'credentialStorage/readStudentsCredentialStorage',
      getSchoolYears: 'dropdown/getSchoolYears',
      getAllCredentials: 'dropdown/getAllCredentials',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async addCredentialForStudent() {
      this.currentStudentCredentialStorage = undefined
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.readStudentsCredentialStorage(this.filter)
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.readStudentsCredentialStorage(this.filter)
    },
    async onUpdateCredentialInStorage(row) {
      this.currentStudentCredentialStorage = row
    },
    async onPreview(row) {
      this.currentStudentCredentialStorage = row
    },
    async onDeleteCredentialInStorage(row) {
    },
    async onCredentialTypeChange(event) {
      this.filter.credentialType = event
    },
    async onSchoolYearChange(event) {
      this.filter.yearId = event
    },
    async onCredentialChange(event) {
      this.filter.credentialId = event
    },
    async onSearch() {
      await this.readStudentsCredentialStorage(this.filter)
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => (
        {
          id: e.id,
          code: e.code,
          name: e.name,
          gender: e.gender,
          birthday: e.birthday,
          address: e.address,
          major: e.major || '',
          trainingSystem: e.trainingSystem || '',
          rank: e.rank || '',
        }
      )) : []
    },
    async onSucceed() {
      await this.readStudentsCredentialStorage(this.filter)
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 130 },
        { index: 3, width: 100 },
        { index: 4, width: 130 },
        { index: 5, width: 130 },
        { index: 6, width: 200 },
        { index: 7, width: 200 },
        { index: 8, width: 130 },
        { index: 9, width: 130 },
        { index: 10, width: 130 },
        { index: 11, width: 130 },
        { index: 12, width: 130 },
        { index: 13, width: 130 },
        { index: 14, width: 130 },
        { index: 15, width: 130 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'NAME',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'BIRTHDAY',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'ADDRESS',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'GENDER_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'ETHNIC_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'NATIONALITY',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'MAJOR_ID',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'CREDENTIAL_NAME',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'YEAR_ID',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'GRADUATION_TEXT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'CODE_CREDENTIAL_STORAGE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'TRAINING_SYSTEM_ID',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 14,
            value: 'CLASS_ID',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 15,
            value: 'SCORE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }

      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('import_VanBangChungChi.xlsx')
    },
  },
}
</script>

<style scoped lang="scss">

</style>

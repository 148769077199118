<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-1">
            <b-row>
              <b-col
                cols="8"
                md="8"
              >
                <h3>{{ modalTitle }}</h3>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  :columns="columns"
                  :rows="sample"
                  :group-options="{
                    enabled: true,
                    collapsable: true
                  }"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'currentCertificateBlankFile' ">
                      <BlankFileView :pdf-file="props.row.currentCertificateBlankFile" />
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span
                      v-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-b-modal.sampleCertificateBlankFileSaveModal
                          class="btn-icon"
                          variant="primary"
                          size="sm"
                          title="Tải lên file phôi mẫu"
                          @click="onUpdateSampleCertificateBlankFile(props.row)"
                        >
                          <feather-icon icon="UploadIcon" />
                        </b-button>

                        <b-button
                          class="btn-icon"
                          variant="danger"
                          size="sm"
                          @click="onDeleteSampleCertificateBlankFile(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                  </template>

                </vue-good-table>
              </b-col>
            </b-row>

          </div>

        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      id="sampleCertificateBlankFileSaveModal"
      ref="sampleCertificateBlankFileSaveModal"
      ok-only
      ok-title="Xác nhận"
      centered
      size="lg"
      title="Thêm/Sửa mẫu phôi văn bằng/chứng chỉ"
      :hide-footer="true"
    >
      <SampleCertificateBlankFileSave
        :item="currentCertificateBlankFile"
        :current-certificate-blank-file-type="currentCertificateBlankFileType"
        @succeed="onSucceed"
      />
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import CredentialImplementSave from '@/views/credential-implement/CredentialImplementSave.vue'
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import SampleCertificateBlankFileSave from '@/views/certificate-blank/SampleCertificateBlankFileSave.vue'
import BlankFileView from '@/views/credential-view-file/BlankFileView.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SampleCertificateBlank',
  directives: {
    Ripple,
  },
  components: {
    SampleCertificateBlankFileSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BlankFileView,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      currentCertificateBlankId: this.dataSend.id,
      modalTitle: `${this.dataSend.name}`,
      currentCertificateBlankFile: [
        {
          name: '',
          description: '',
          credentialTypeId: 0,
          type: '',
          number: 0,
          codeStart: '',
          codeEnd: '',
          linkFileCover: '',
          linkFileMain: '',
          fileCoverId: 0,
          fileMainId: 0,
          status: 1,
        },
      ],
      currentCertificateBlankFileType: [
        {
          originalIndex: 0,
          currentCertificateBlankFile: '',
        },
      ],
      sample: [
        {
          type: 'Mặt trước',
          children: [
            { currentCertificateBlankFile: '' },
          ],
        },
        {
          type: 'Mặt sau',
          children: [
            { currentCertificateBlankFile: '' },
          ],
        },
      ],
      columns: [
        {
          label: '',
          field: 'type',
          width: '150px',
        },
        {
          label: '',
          field: 'currentCertificateBlankFile',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'certificateBlank/statuses',
      certificateBlankFiles: 'certificateBlank/certificateBlankFiles',
    }),

  },
  watch: {
    certificateBlankFiles: {
      immediate: true,
      handler(newValue, oldValue) {
        this.sample = this.generateSample(newValue)
      },
    },
  },
  async created() {
    await this.readCertificateBlankFiles(this.currentCertificateBlankId)
    this.currentCertificateBlankFile = { ...this.dataSend }
    this.currentCertificateBlankId = this.dataSend.id
    const {
      fileCoverId, fileMainId, linkFileCover, linkFileMain, linkFileCoverName, linkFileMainName,
    } = this.certificateBlankFiles[0]
    this.currentCertificateBlankFile = {
      ...this.currentCertificateBlankFile,
      fileCoverId,
      fileMainId,
      linkFileCover,
      linkFileCoverName,
      linkFileMain,
      linkFileMainName,
    }
  },
  methods: {
    ...mapActions({
      readCertificateBlankFiles: 'certificateBlank/readCertificateBlankFiles',
      updateCertificateBlank: 'certificateBlank/updateCertificateBlank',
    }),
    generateSample(certificateBlankFiles) {
      const sample = [
        {
          type: 'Mặt 1',
          children: [],
        },
        {
          type: 'Mặt 2',
          children: [],
        },
      ]

      certificateBlankFiles.forEach(file => {
        sample[0].children.push({
          currentCertificateBlankFile: file.linkFileCover || '',
          currentCertificateBlankFileName: file.linkFileCoverName || '',
        })
        sample[1].children.push({
          currentCertificateBlankFile: file.linkFileMain || '',
          currentCertificateBlankFileName: file.linkFileMainName || '',
        })
      })

      return sample
    },

    onUpdateSampleCertificateBlankFile(row) {
      this.currentCertificateBlankFileType = { ...row }
    },
    async onDeleteSampleCertificateBlankFile(row) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: 'Xóa mẫu phôi',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          if (row.originalIndex === 0) {
            this.currentCertificateBlankFile.fileCoverId = ''
          }
          if (row.originalIndex === 1) {
            this.currentCertificateBlankFile.fileMainId = ''
          }
          const response = await this.updateCertificateBlank(this.currentCertificateBlankFile)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.getDataSourcesFromStore()
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.updateCertificateBlank(this.currentCertificateBlankFile)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.readCertificateBlankFiles(this.currentCertificateBlankId)
        const {
          fileCoverId, fileMainId, linkFileCover, linkFileMain, linkFileCoverName, linkFileMainName,
        } = this.certificateBlankFiles[0]
        this.currentCertificateBlankFile = {
          ...this.currentCertificateBlankFile,
          fileCoverId,
          fileMainId,
          linkFileCover,
          linkFileCoverName,
          linkFileMain,
          linkFileMainName,
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },

}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="image-certificate">
    <img
        class="background-certificate"
        :src="BlankFileUrl"
        alt="Background Image"
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    pdfFile: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      BlankFileUrl: this.pdfFile,
    }
  },
  watch: {
    pdfFile: {
      immediate: true,
      handler(newBlankFile) {
        this.updateBlankFileUrl(newBlankFile)
      },
    },
  },
  // eslint-disable-next-line no-empty-function
  async created() {
  },
  methods: {
    ...mapActions({
      getPathFileSample: 'certificateBlank/getPathFileSample',
    }),
    async updateBlankFileUrl(newBlankFile) {
      this.BlankFileUrl = await this.getPathFileSample({
        pathFile: newBlankFile,
        contentType: 'image/jpeg',
      })
    },
  },
}
</script>
<style>
.image-certificate {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.background-certificate {
  position: relative;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
</style>

<script>
import {
  BButton, BButtonGroup, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  name: 'PdfFileView',
  components: {
    BButton,
    BButtonGroup,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    pdfFile: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      pdfUrl: '',
    }
  },
  watch: {
    pdfFile: {
      immediate: true,
      handler(newPdfFile) {
        this.loadFile(newPdfFile)
      },
    },
  },
  methods: {
    ...mapActions({
      getPathFileSample: 'certificateBlank/getPathFileSample',
    }),
    async loadFile(newPdfFile) {
      try {
        if (newPdfFile.endsWith('.pdf')) {
          this.pdfUrl = await this.getPathFileSample({
            pathFile: newPdfFile,
            contentType: 'application/pdf',
          })
        } else {
          console.error('Unsupported file format')
        }
      } catch (error) {
        console.error('Error downloading or converting file:', error)
      }
    },
  },
}
</script>

<template>
  <b-row>
    <b-col>
      <div class="pdf-container">
        <iframe
          v-if="pdfUrl"
          ref="pdfViewer"
          :src="pdfUrl"
          width="100%"
          height="600px"
          style="position: relative;"
        />
      </div>
    </b-col>
  </b-row>
</template>

<style scoped lang="scss">
.pdf-container {
  position: relative;
}
</style>
